//
// Global init of core components
//

// Init components
var KTComponents = function () {
    // Public methods
    return {
        init: function () {
					if (typeof KTApp != 'undefined') {
            KTApp.init();
					}
					if(typeof KTDrawer != 'undefined') {
						KTDrawer.init();
					}
					if (typeof KTMenu != 'undefined') {
						KTMenu.init();
					}
					if (KTScroll != 'undefined') {
						KTScroll.init();
					}					
					if(typeof KTSticky != 'undefined') {
						KTSticky.init();
					}
					if (typeof KTToggle != 'undefined') {
						KTToggle.init();
					}
					if (typeof KTScrolltop != 'undefined') {
						KTScrolltop.init();
					}
        }
    }	
}();

// On document ready
if (document.readyState === "loading") {
	document.addEventListener("DOMContentLoaded", function() {
		KTComponents.init();
	});
 } else {
	KTComponents.init();
 }

 // Init page loader
window.addEventListener("load", function() {
	if(typeof KTApp != 'undefined') {
		KTApp.hidePageLoading();
	}
});

// Declare KTApp for Webpack support
if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
	window.KTComponents = module.exports = KTComponents;
}